<template>
    <div id="pictureBackground">
        <div class="container col-10 col-md-8 col-lg-6">
            <p style="text-align: center;">親愛的親友們</p>
            <p style="text-align: center;">
                柏丞與彥伶經過十年的愛情長跑，從在學時的天真浪漫到畢業後一起陪伴彼此奮鬥，共享歡樂與共同承擔難關，終於要結婚了， 誠心地邀請每一位曾參與我們成長過程中的您來參與我們的婚禮！
            </p>
            <div style="text-align: center;">
                如填完表單，但想修改填寫內容，重新填寫即可，會用<strong>最後一筆</strong>填寫的資料為準，請於<strong>9/1</strong>前填寫完畢，方便新人們統計人數唷!
            </div>
            <div style="text-align: center;">
                <img style="border-radius: 3%;" :src='require(`../assets/F_7244.jpg`)' width="65%">
            </div>
            <div class="info" style="text-align: center;"><strong>時間:</strong> 2024/10/20 中午12:00
                <strong>(準時開桌)</strong></div>
            <div class="info" style="text-align: center;"><strong>地點:</strong> 新天地旱溪東區店 凡爾賽宮</div>
            <div class="info" style="text-align: center;"><strong>地址:</strong> 401台中市東區旱溪東路一段456號 </div>
            <div style="text-align: center;">
                <img width="85%" @click="downloadInformation" :src='require(`../assets/information.jpg`)'>
            </div>


            <div style="font-size: 24; text-align: center;"><strong>您是否願意參加?</strong></div>
            <b-input-group class="row d-flex justify-content-center">
                <!-- <b-button id="show-btn" @click="$bvModal.show('bv-modal-example')" variant="primary">我要參加</b-button>


                <b-modal id="bv-modal-example" hide-footer>
                    <template #modal-title>
                        <h4>提示訊息</h4>
                    </template>
                    <div class="d-block text-center">
                        <h3>抱歉~統計時間已結束</h3>
                    </div>
                    <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')">關閉</b-button>
                </b-modal> -->
                <b-button class="ml-2" variant="primary" @click="attend = true;">我要參加</b-button>
                <b-button class="ml-2" variant="secondary" @click="$bvModal.show('bv-modal-example')">無法參加，祝福你們</b-button>
            </b-input-group>
        </div>
    </div>
</template>
<script>

export default {
    props: [
        "view_index",
    ],
    data() {
        return {
            attend: null,
        }
    },
    watch: {
        attend: function () {
            if (this.attend) {
                this.$emit('update', 2);
            } else {
                this.$emit('update', 8);
            }
        }
    },
    methods: {
        downloadInformation() {
            // Specify the path to the file in the assets folder
            const filePath = '/柏丞彥伶1020中午婚禮交通資訊.jpg';
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = filePath;
            link.download = '柏丞彥伶1020中午婚禮交通資訊.jpg'; // Specify the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}
</script>
<style scoped>
#pictureBackground {
    z-index: 0;
    /* background-image: url('~@/assets/wall.jpg'); */
    /* Ensure the background covers the entire div */
    background-size: covers;

    /* Position the background */
    /* background-position: center; */
    background-position: center;

    /* Make sure the div takes up the entire window */
    /* width: 100vw;
  height: 100vh; */

    /* Optionally prevent the background from repeating */
    background-repeat: no-repeat;
    /* background-repeat: repeat; */
    /* Set a fallback background color */
    /* background-color: #ffffff; */
    background-color: rgb(219, 178, 130);

    /* or any color that suits your design */
}

.container {
    background-color: white;
    position: relative;
    top: 25px;
    border-radius: 5px;
    z-index: 1;
    padding: 20px;
}

.info {
    font-size: 16px;
    color: DarkBlue;
}
</style>